<template>
  <a-locale-provider :locale="zh_CN">
    <div id="app">

      <router-view>


      </router-view>

    </div>
  </a-locale-provider>
</template>
  
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
import { getAction } from './request/http.js'

moment.locale("zh-cn");
export default {
  name: 'App',
  data() {
    return {
      zh_CN
    };
  },
  methods: {
    getRandom() {

      var arr = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],

        num = "";

      for (var i = 0; i < 32; i++) {

        num += arr[parseInt(Math.random() * 36)];
      }

      return num.toLowerCase();

    }
  },
  mounted() {
    
    this.path = this.$router.path;
    if (this.path != '/'){
      this.$router.replace('/')
    }

    if (!localStorage.getItem('browser_id')) {
      localStorage.setItem('browser_id', this.getRandom())
    }
  },
  watch: {
    $route(to, from) {
      this.path = to.path
    },
  }
}
</script>
  
  
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  width: 100%;
  height: 100%;
  font-family: WorkSans, Arial !important;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-warp {
  flex-wrap: wrap;
}

.list-scroll {
  width: 100%;
  overflow-y: scroll;
}

.list-scroll::-webkit-scrollbar {
  display: none;
}

.spin {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e2e2e261;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
*:focus{
  outline: none;
}
</style>
  