import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
  state:{
    language:null,
  },
  mutations:{
    changeLanguage(state,payload){
      state.language = payload
    },
    
  },
  getters:{
    getLanguage:(state)=>{
      return state.language
    }
  }
})
export default store